/* bootstrap-datetimepicker adjustment for BS4 */

.bootstrap-datetimepicker-widget .collapse.in {
    display: block;
    visibility: visible;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
    width: 266px;
    height: 289px;
}

.bootstrap-datetimepicker-widget table td.day {
    height: 20px;
    line-height: 20px;
    width: 20px;
}

.bootstrap-datetimepicker-widget .picker-switch td span {
    height: 35px;
}

.table-condensed>thead>tr>th, .table-condensed>tbody>tr>th, .table-condensed>tfoot>tr>th, .table-condensed>thead>tr>td, .table-condensed>tbody>tr>td, .table-condensed>tfoot>tr>td {
    padding: 5px;
}