body {
  line-height: 1.25;
}

.form-control:focus {
  box-shadow: 0 0 0 0.075rem rgba(0, 123, 255, 0.25);
}

.form-select:focus {
  box-shadow: 0 0 0 0.075rem rgba(0, 123, 255, 0.25);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  box-shadow: 0 0 0 0.075rem rgba(220, 53, 69, 0.25);
}

.form-control.is-invalid {
  background-image: none;
}

select.form-select.is-invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
}

.w-90px {
  min-width: 90px;
}

.w-100px {
  min-width: 100px;
}

.w-200px {
  min-width: 200px;
}

/* width */

::-webkit-scrollbar {
  width: 10px;
  /* display: none; */
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}

/* html {
    overflow-y: overlay;
} */

.spinner-border {
  border-top-width: 0.1em;
  border-right-width: 0.1em;
  border-bottom-width: 0.1em;
  border-left-width: 0.1em;
}

dialog {
  border: none;
  padding: initial;
  background: transparent;
}

.input-inner-addon {
  position: relative;
}

/* style glyph */
.input-inner-addon .fa {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

/* align glyph */
.input-left-addon .fa {
  left: 0px;
}

.input-right-addon .fa {
  right: 0px;
}

/* add padding  */
.input-left-addon input {
  padding-left: 30px;
}

.input-right-addon input {
  padding-right: 30px;
}