.animate-slide-in-right.au-enter {
    transform: translateX(100%);
}

.animate-slide-in-right.au-enter-active {
    animation: SlideInRight 1s;
}

@keyframes SlideInRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.animate-slide-out-right.au-leave-active {
    animation: SlideOutRight 1s;
}

@keyframes SlideOutRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

.animate-slide-in-left.au-enter {
    transform: translateX(-100%);
}

.animate-slide-in-left.au-enter-active {
    animation: SlideInLeft 1s;
}

@keyframes SlideInLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.animate-slide-out-left.au-leave-active {
    animation: SlideOutLeft 1s;
}

@keyframes SlideOutLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.animate-fade-in.au-enter {
    opacity: 0;
}

.animate-fade-in.au-enter-active {
    animation: FadeIn 0.8s;
}

@keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animate-fade-out.au-leave-active {
    animation: FadeOut 0.8s;
}

@keyframes FadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}